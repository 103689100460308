import * as styles from "./blog-post-preview-grid.module.css";
import BlogPostPreview from "./blog-post-preview";
import { Link } from "gatsby";
import React from "react";

function BlogPostPreviewGrid(props) {

  function shouldBeTopStory(index) {
    // if topStoryFrequency is 0, there shouldn't be any top stories.
    if (props.topStoryFrequency == 0) {
      return false
    }

    // first story should always be a top story. (if topStoryFrequency > 0)
    if (index == 0) {
      return true
    }

    // if it is ith post (where i is props.topStoryFrequency) and there is less than k top stories
    // already shown (where k is props.maxNumTopStory)
    if (index % props.topStoryFrequency == 0 && index < props.maxNumTopStory * props.topStoryFrequency) {
      return true
    } else {
      return false
    }
  }

  return (
    <div className={styles.root}>
      {props.title && <h2 className={styles.headline}>{props.title}</h2>}
      <ul className={styles.grid}>
        {props.nodes &&
          props.nodes.map((node, index) => (
            <li key={node.id} className={shouldBeTopStory(index) ? styles.topStory : null}>
              <BlogPostPreview {...node} showBio={shouldBeTopStory(index)}/>
            </li>
          ))}
      </ul>
      {props.browseMoreHref && (
        <div className={styles.browseMoreNav}>
          <Link to={props.browseMoreHref}>Browse more</Link>
        </div>
      )}
    </div>
  );
}

BlogPostPreviewGrid.defaultProps = {
  title: "",
  nodes: [],
  browseMoreHref: "",
  topStoryFrequency: 0, // e.g. if 3, every third story is top, starting from 0 (0, 3, 6, etc.)
  maxNumTopStory: 0, // e.g. if 2, don't show 3 or more top stories even if there's room
};

export default BlogPostPreviewGrid;
